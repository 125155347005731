import { HomeClientsModel } from 'models/home/homeClients.model'
import { t } from '@lingui/macro'

export const homeClientsData: HomeClientsModel[] = [
  {
    title: 'Out-start',
    text:
      'Nous transformons et digitalisons vos problémaqtiques d’entreprise en vous accompagnant dans la création de startups à succès.',
    langID: t`home.clients.text.1`,
  },
  {
    title: 'Co-start',
    text: `Nous associons notre savoir-faire à des entreprises et entrepreneurs pour co-créer et co-développer des startups de rupture sur leur marché.`,
    langID: t`home.clients.text.2`,
  },
  {
    title: 'In-start',
    text:
      'Notre équipe à l’esprit entrepreneurial analyse en permanence les marchés pour vous proposer et créer les startups de demain.',
    langID: t`home.clients.text.3`,
  },
]
