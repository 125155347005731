import React, { FunctionComponent } from 'react'
import { Trans } from '@lingui/macro'
import { TeamsListContainer, TeamsListItem } from './TeamsList.style'
import { SecondaryTitle } from 'styles/Global'
import { dataTeams } from './TeamsList.data'
import Icons from 'components/atoms/Icons/Icons'

interface Props {}

const TeamsList: FunctionComponent<Props> = () => {
  return (
    <TeamsListContainer>
      {dataTeams.map((item, i) => (
        <TeamsListItem key={i}>
          <Icons name={item.icon} />
          <SecondaryTitle as="h3">
            <Trans id={item.langID} />
          </SecondaryTitle>
        </TeamsListItem>
      ))}
    </TeamsListContainer>
  )
}

export default TeamsList
