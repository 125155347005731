import React, { FunctionComponent } from 'react'
import { Trans } from '@lingui/macro'
// @ts-ignore
import { LocalizedLink as Link } from 'gatsby-theme-i18n'
import { ImageQueryModel } from 'models/imageQuery.model'
import { ButtonRed, MainText, SecondaryTitle } from 'styles/Global'
import {
  HomeClientsButtons,
  HomeClientsCloud,
  HomeClientsClouds,
  HomeClientsContainer,
  HomeClientsItem,
  HomeClientsList,
  HomeClientsSection,
  HomeClientsTitle,
} from './HomeClients.style'
import { homeClientsData } from './HomeClients.data'
import Image from 'components/Image'

interface Props {
  images: ImageQueryModel
}

const HomeClients: FunctionComponent<Props> = ({ images }) => {
  return (
    <HomeClientsSection>
      <HomeClientsContainer>
        <HomeClientsTitle as="h2">
          <Trans id="home.clients.title">
            Vous êtes entrepreneur, grand compte ou investisseur&nbsp;?
          </Trans>
        </HomeClientsTitle>
        <HomeClientsButtons>
          <ButtonRed as={Link} to="/studio">
            <Trans id="home.clients.button">
              Co-créons la startup de demain
            </Trans>
          </ButtonRed>
          <ButtonRed
            as="a"
            href="http://product.redfabriq.com/"
            target="_blank"
          >
            <Trans id="home.clients.button2">
              Consultez notre conseil sur les produits
            </Trans>
          </ButtonRed>
        </HomeClientsButtons>
        <HomeClientsList>
          {homeClientsData.map((item, i) => (
            <HomeClientsItem key={i}>
              <SecondaryTitle as="h3">{item.title}</SecondaryTitle>
              <MainText>
                <Trans id={item.langID} />
              </MainText>
            </HomeClientsItem>
          ))}
        </HomeClientsList>
        <HomeClientsClouds>
          <HomeClientsCloud className="rellax">
            <Image fixed={images.cloud1.fixed} />
          </HomeClientsCloud>
          <HomeClientsCloud className="rellax" data-rellax-speed="-1">
            <Image fixed={images.cloud2.fixed} />
          </HomeClientsCloud>
          <HomeClientsCloud>
            <div className="rellax" data-rellax-speed="1">
              <Image fixed={images.cloud3.fixed} />
            </div>
          </HomeClientsCloud>
          <HomeClientsCloud className="rellax" data-rellax-speed="-1">
            <Image fixed={images.cloud4.fixed} />
          </HomeClientsCloud>
        </HomeClientsClouds>
      </HomeClientsContainer>
    </HomeClientsSection>
  )
}

export default HomeClients
