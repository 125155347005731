import { graphql, useStaticQuery } from 'gatsby'
import { getFluidImage, getFixedImage } from 'helpers/getFluidImage'

export const fetchHomeImg = () => {
  const {
    home,
    clientsCloud1,
    clientsCloud2,
    clientsCloud3,
    clientsCloud4,
    hero,
    teams,
  } = useStaticQuery(graphql`
    query {
      home: allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "home" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      hero: allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "home/hero" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      teams: allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "home/teams" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      clientsCloud1: file(relativePath: { eq: "home/clients/cloud1.png" }) {
        base
        childImageSharp {
          fixed(width: 140, height: 105) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      clientsCloud2: file(relativePath: { eq: "home/clients/cloud2.png" }) {
        base
        childImageSharp {
          fixed(width: 110, height: 83) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      clientsCloud3: file(relativePath: { eq: "home/clients/cloud3.png" }) {
        base
        childImageSharp {
          fixed(width: 60, height: 45) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      clientsCloud4: file(relativePath: { eq: "home/clients/cloud4.png" }) {
        base
        childImageSharp {
          fixed(width: 60, height: 45) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return {
    home: getFluidImage(home),
    hero: getFluidImage(hero),
    teams: getFluidImage(teams),
    clients: {
      cloud1: getFixedImage(clientsCloud1),
      cloud2: getFixedImage(clientsCloud2),
      cloud3: getFixedImage(clientsCloud3),
      cloud4: getFixedImage(clientsCloud4),
    },
  }
}
