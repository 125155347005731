import styled from 'styled-components'

export const ImageContainer = styled.div`
  width: 60%;
  margin: 0 200px 0 auto;
  position: relative;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    max-width: 50%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-right: 100px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    max-width: 100%;
  }
`

export const ImageExtra = styled.div`
  position: absolute;
  right: -150px;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    right: -80px;
  }
`

export const ImageExtra1 = styled(ImageExtra)`
  top: 0;
  width: 100px;
  height: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 50px;
  }
`

export const ImageExtra2 = styled(ImageExtra)`
  top: 200px;
  width: 50px;
  height: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    top: 100px;
    width: 30px;
    height: 30px;
  }
`

export const ImageExtra3 = styled(ImageExtra)`
  top: 100px;
  right: -100px;
  width: 50px;
  height: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    right: -50px;
    top: 50px;
    width: 30px;
    height: 30px;
  }
`
