import styled from 'styled-components'
import { SecondaryTitle } from 'styles/Global'

export const TeamsListContainer = styled.ul`
  margin-top: 3.5rem;
`

export const TeamsListItem = styled.li`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
  ${SecondaryTitle} {
    margin-left: 1.5rem;
  }
`
