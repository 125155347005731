import { IconsName } from 'assets/svg/Icons.enum'
import { t } from '@lingui/macro'

export const dataTeams = [
  {
    text: 'Business Product',
    icon: IconsName.BUSINESS,
    langID: t`home.teams.item.1`,
  },
  {
    text: 'Stratégie et Finance',
    icon: IconsName.STRATEGY,
    langID: t`home.teams.item.2`,
  },
  {
    text: 'Design UX',
    icon: IconsName.EDIT,
    langID: t`home.teams.item.3`,
  },
  {
    text: 'Tech et IOT',
    icon: IconsName.TERMINAL,
    langID: t`home.teams.item.4`,
  },
  {
    text: 'Marketing et Sales',
    icon: IconsName.CHART,
    langID: t`home.teams.item.5`,
  },
]
