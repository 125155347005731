import styled from 'styled-components'
import { motion } from 'framer-motion'
import { PrimaryTitle, Row, TextRed } from 'styles/Global'

export const HomeHeroContainer = styled.section`
  position: relative;
  min-height: calc(100vh - 95px);
  display: flex;
  align-items: center;
  margin-top: 95px;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const HomeHeroTitle = styled(PrimaryTitle)`
  margin-bottom: 6rem;
  max-width: 592px;
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    max-width: 50%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    max-width: 50%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding-top: 6rem;
    max-width: 80%;
    margin-bottom: 0;
  }
`

export const HomeHeroImage = styled(motion.div)`
  max-width: 40%;
  display: block;
  margin: 0 auto;
  width: 100%;
  position: relative;
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    max-width: 30%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    max-width: 50%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    max-width: 80%;
    margin-top: 2rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    max-width: 100%;
  }
`

export const HomeHeroCloud = styled.div<{ lastImage?: boolean }>`
  position: absolute;
  width: 90px;
  height: 90px;
  top: ${({ lastImage }) => (lastImage ? 'unset' : 0)};
  left: ${({ lastImage }) => (lastImage ? 'unset' : '30px')};
  bottom: ${({ lastImage }) => (lastImage ? '-50px' : 'unset')};
  right: ${({ lastImage }) => (lastImage ? '-20px' : 'unset')};
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 70px;
    height: 70px;
    top: ${({ lastImage }) => (lastImage ? 'unset' : 0)};
    left: ${({ lastImage }) => (lastImage ? 'unset' : '50px')};
    bottom: ${({ lastImage }) => (lastImage ? '-50px' : 'unset')};
    right: ${({ lastImage }) => (lastImage ? 0 : 'unset')};
  }
`

export const HomePortfolio = styled(Row)``

export const HomePortfolioImage = styled.div`
  max-width: 80%;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin: 0 auto;
    max-width: 50%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    max-width: 100%;
  }
`

export const HomeTeams = styled(Row)``

export const HomeRecruitment = styled(Row)`
  margin-left: 7rem;
  padding: 4rem 0 4.5rem;
  position: relative;
  ${PrimaryTitle} {
    margin-bottom: 1rem;
    margin-right: 7rem;
  }
  ${TextRed} {
    p {
      display: inline-block;
    }
    margin-bottom: 2.5rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-left: 5rem;
    padding-top: 5rem;
    ${PrimaryTitle} {
      margin-right: 5rem;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-left: 3rem;
    padding-bottom: 2.5rem;
    ${PrimaryTitle} {
      margin-right: 3rem;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    margin-left: 1.5rem;
    ${PrimaryTitle} {
      margin-right: 1.5rem;
    }
  }
`

export const HomeRecruitmentImage = styled(motion.div)`
  transform: scaleX(-1);
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-top: 2.5rem;
  }
`

export const HomeContact = styled(Row)`
  padding: 0 0 7.75rem;
  ul {
    justify-content: flex-end;
    margin-top: 2rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding-top: 6rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    padding-bottom: 1rem;
  }
`

export const HomeContactImage = styled.div``
