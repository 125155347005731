import styled from 'styled-components'
import { motion } from 'framer-motion'
import { ButtonRed, Container, PrimaryTitle, SecondaryTitle } from "styles/Global"

export const HomeClientsSection = styled.section`
  text-align: center;
  padding: 11rem 0 66px;
  position: relative;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 6rem 0;
  }
`

export const HomeClientsContainer = styled(Container)`
  position: relative;
`

export const HomeClientsButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  ${ButtonRed}:first-child {
    margin-right: 1.5rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    ${ButtonRed} {
      font-size: 1rem;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: column;
    ${ButtonRed}:first-child {
      margin-bottom: 1.5rem;
      margin-right: 0;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    ${ButtonRed} {
      font-size: 14px;
    }
  }
`;

export const HomeClientsTitle = styled(PrimaryTitle)`
  max-width: 592px;
  margin: 0 auto 2.5rem;
  position: relative;
  z-index: 1;
`

export const HomeClientsList = styled.ul`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 4rem;
  position: relative;
  z-index: 1;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-top: 3rem;
    flex-direction: column;
    align-items: center;
  }
`

export const HomeClientsItem = styled.li`
  max-width: 280px;
  &:not(:last-child) {
    margin-right: 6rem;
  }
  ${SecondaryTitle} {
    margin-bottom: 10px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    max-width: 33%;
    &:not(:last-child) {
      margin-right: 2rem;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    max-width: 100%;
    &:not(:last-child) {
      margin: 0 0 2.5rem;
    }
  }
`

export const HomeClientsCloud = styled(motion.li)<{ ref?: any }>`
  position: absolute;
`

export const HomeClientsClouds = styled.ul`
  ${HomeClientsCloud} {
    &:first-child {
      top: 35px;
      left: 80px;
    }
    &:nth-child(2) {
      top: 160px;
      right: 90px;
    }
    &:nth-child(3) {
      top: 80px;
      right: 220px;
    }
    &:last-child {
      top: -100px;
      left: 250px;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    ${HomeClientsCloud} {
      &:first-child {
        top: 80px;
        left: 0;
      }
      &:nth-child(2) {
        right: -50px;
      }
      &:nth-child(3) {
        right: 80px;
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: none;
  }
`
