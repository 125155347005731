import React, {
  FunctionComponent,
  Ref,
  useEffect,
  useRef,
  useState,
} from 'react'
// @ts-ignore
import { LocalizedLink as Link } from 'gatsby-theme-i18n'
import { init } from 'ityped'
import { I18n } from '@lingui/react'
import { t, Trans } from '@lingui/macro'
import sanitize from 'sanitize-html'
// @ts-ignore
import { useLocalization } from 'gatsby-theme-i18n'
import {
  HomeContact,
  HomeContactImage,
  HomeHeroContainer,
  HomeHeroTitle,
  HomeHeroImage,
  HomePortfolio,
  HomePortfolioImage,
  HomeRecruitment,
  HomeRecruitmentImage,
  HomeTeams,
  HomeHeroCloud,
} from 'styles/pages/home.style'
import {
  BackgroundBlack,
  ButtonBlack,
  ButtonRed,
  Col2,
  Container,
  PrimaryTitle,
  TextRed,
} from 'styles/Global'
import { fetchHomeImg } from 'hooks/fetch-home-img'

import Layout from 'components/Layout'
import SEO from 'components/Seo'
import Image from 'components/Image'
import IndicatorScroll from 'components/atoms/IndicatorScroll/IndicatorScroll'
import HomeTeamsImage from 'components/atoms/HomeTeamsImage/HomeTeamsImage'
import PortfolioLogos from 'components/atoms/PortfolioLogos/PortfolioLogos'
import ListSocials from 'components/atoms/ListSocials/ListSocials'
import TeamsList from 'components/atoms/TeamsList/TeamsList'
import Footer from 'components/molecules/Footer/Footer'
import HomeClients from 'components/molecules/HomeClients/HomeClients'
import HomeOffers from 'components/molecules/HomeOffers/HomeOffers'
import Header from 'components/organisms/Header/Header'

interface Props {}

const IndexPage: FunctionComponent<Props> = () => {
  const [isDark, setDarkMode] = useState(true)
  const headerRef: Ref<HTMLElement> = useRef(null)
  const homeBlack: Ref<HTMLElement> = useRef(null)
  const typingRef: Ref<HTMLParagraphElement> = useRef(null)
  const { locale } = useLocalization()
  const { hero, home, clients, teams } = fetchHomeImg()

  const handleHeaderColor = () => {
    const headerHeight = headerRef.current?.clientHeight ?? 0
    const homeBlackHeight = homeBlack.current?.clientHeight ?? 0
    const homeBlackTop = homeBlack.current?.offsetTop ?? 0

    if (window.pageYOffset + headerHeight >= homeBlackHeight + homeBlackTop) {
      setDarkMode(false)
    } else {
      setDarkMode(true)
    }
  }

  const typingEffect = () => {
    const element = typingRef.current || ''
    const text =
      locale === 'fr'
        ? 'Votre job de rêves est peut-être ici...'
        : 'Your dream job may be here...'

    init(element, {
      showCursor: true,
      strings: [text],
      typeSpeed: 100,
      startDelay: 1000,
      backDelay: 6000,
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleHeaderColor)
    typingEffect()

    return () => window.removeEventListener('scroll', handleHeaderColor)
  }, [])

  return (
    <Layout>
      <I18n>
        {({ i18n }) => (
          <SEO
            title="Redfabriq Startup Studio"
            description={i18n._('home.metaDescription')}
          />
        )}
      </I18n>
      <Header darkMode={isDark} ref={headerRef} />
      <BackgroundBlack ref={homeBlack}>
        <Container>
          <HomeHeroContainer>
            <HomeHeroTitle>
              <Trans id="home.title">
                <TextRed>Transformez</TextRed> vos problématiques d’entreprise
                en startups à succès.
              </Trans>
            </HomeHeroTitle>
            <HomeHeroImage>
              <HomeHeroCloud>
                <div className="rellax" data-rellax-speed="-3">
                  <Image
                    fluid={hero.homeHeroCloud1.fluid}
                    alt={hero.homeHeroCloud1.alt}
                  />
                </div>
              </HomeHeroCloud>
              <div className="rellax">
                <Image fluid={hero.homeHero.fluid} alt={hero.homeHero.alt} />
              </div>
              <HomeHeroCloud lastImage>
                <div className="rellax" data-rellax-speed="-2">
                  <Image
                    fluid={hero.homeHeroCloud2.fluid}
                    alt={hero.homeHeroCloud2.alt}
                  />
                </div>
              </HomeHeroCloud>
            </HomeHeroImage>
            <IndicatorScroll text="Scroll" />
          </HomeHeroContainer>
        </Container>
        <HomeClients images={clients} />
        <Container>
          <HomeOffers />
          <HomePortfolio isReverse>
            <Col2>
              <PrimaryTitle as="h2">
                <Trans id="home.portfolio.title">
                  Redfabriq crée des startups innovantes et sophistiquées.
                </Trans>
              </PrimaryTitle>
              <ButtonRed as={Link} to="/portfolio">
                <Trans id="home.portfolio.button">Découvrez nos succès</Trans>
              </ButtonRed>
              <PortfolioLogos isHome />
            </Col2>
            <Col2>
              <HomePortfolioImage className="rellax">
                <Image fluid={home.avatar.fluid} alt={home.avatar.alt} />
              </HomePortfolioImage>
            </Col2>
          </HomePortfolio>
        </Container>
      </BackgroundBlack>
      <Container>
        <HomeTeams>
          <Col2>
            <PrimaryTitle as="h2">
              <Trans id="home.teams.title">
                Nous couvrons toute la chaîne de création d’une startup
              </Trans>
            </PrimaryTitle>
            {/*<ButtonBlack as={Link} to="/talents">Rencontrez nos talents</ButtonBlack>*/}
            <TeamsList />
          </Col2>
          <Col2>
            <HomeTeamsImage sources={teams} />
          </Col2>
        </HomeTeams>
        <HomeContact isReverse>
          <Col2>
            <PrimaryTitle as="h2">
              <Trans id="home.contact.title">
                Vous souhaitez nous parler de votre projet&nbsp;?
              </Trans>
            </PrimaryTitle>
            <ButtonBlack as={Link} to="/contact">
              <Trans id="home.contact.button">Prenons un café</Trans>
            </ButtonBlack>
            <ListSocials />
          </Col2>
          <Col2>
            <HomeContactImage className="rellax">
              <Image
                fluid={home.homeContact.fluid}
                alt={home.homeContact.alt}
              />
            </HomeContactImage>
          </Col2>
        </HomeContact>
      </Container>
      <HomeRecruitment>
        <IndicatorScroll text="Remonte" direction="up" />
        <Col2>
          <I18n>
            {({ i18n }) => (
              <PrimaryTitle
                as="h2"
                dangerouslySetInnerHTML={{
                  __html: sanitize(i18n._(t`home.recruitment.title`)),
                }}
              />
            )}
          </I18n>
          <TextRed as="div">
            <p ref={typingRef} />
          </TextRed>
          <ButtonBlack as={Link} to="/jobs">
            <Trans id="home.recruitment.button">Rejoignez-nous</Trans>
          </ButtonBlack>
        </Col2>
        <Col2>
          <HomeRecruitmentImage>
            <Image fluid={home.handAvatar.fluid} alt={home.handAvatar.alt} />
          </HomeRecruitmentImage>
        </Col2>
      </HomeRecruitment>
      <Footer />
    </Layout>
  )
}

export default IndexPage
