import styled from 'styled-components'
import { motion } from 'framer-motion'

export const HomeOffersContainer = styled.section`
  padding: 6rem 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    text-align: center;
  }
`

export const HomeOffersList = styled.ul`
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    flex-wrap: wrap;
    align-items: flex-start;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex-direction: column;
  }
`

export const HomeOffersItem = styled.li`
  max-width: calc(25% - 2rem);
  text-align: center;
  z-index: 1;
  width: 100%;
  position: relative;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    max-width: calc(50% - 2rem);
    &:nth-child(-n + 2) {
      margin-bottom: 3rem;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    max-width: 100%;
    margin-bottom: 2.5rem;
    &:nth-child(-n + 2) {
      margin-bottom: 2.5rem;
    }
  }
`

export const HomeOffersClouds = styled(motion.div)`
  position: absolute;
  bottom: -20px;
  left: 2.5rem;
  display: flex;
  align-items: flex-start;
  img {
    display: inline-block;
    &:first-child {
      width: auto;
      height: 20px;
    }
    &:last-child {
      width: auto;
      height: 90px;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    left: 20px;
    bottom: 0;
    img:last-child {
      width: 60%;
      height: auto;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    top: -20px;
    right: 0;
    img:last-child {
      max-width: 100px;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    top: -10px;
    right: 50px;
    img {
      &:first-child {
        height: 15px;
        margin-right: 1rem;
      }
      &:last-child {
        width: 60px;
      }
    }
  }
`
