import { HomeOffersModel } from 'models/home/homeOffers.model'
import { t } from '@lingui/macro'

export const homeOffersData: HomeOffersModel[] = [
  {
    id: 0,
    text: 'Lancez votre startup rapidement.',
    langID: t`home.offers.text.1`,
  },
  {
    id: 1,
    text: 'Couplez intelligemment tech et business.',
    langID: t`home.offers.text.2`,
  },
  {
    id: 2,
    text: 'Maîtrisez le risque d’échec.',
    langID: t`home.offers.text.3`,
  },
  {
    id: 3,
    text: 'Profitez d’une expertise produit.',
    langID: t`home.offers.text.4`,
  },
]
