import React, {
  FunctionComponent,
  MouseEvent,
  Ref,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Trans } from '@lingui/macro'
import {
  HomeOffersClouds,
  HomeOffersContainer,
  HomeOffersItem,
  HomeOffersList,
} from './HomeOffers.style'
import { homeOffersData } from './HomeOffers.data'
import { MainText, SecondaryTitle } from 'styles/Global'
import HomeCloud from 'assets/images/home/offers/homeCloud.png'

interface Props {}

const HomeOffers: FunctionComponent<Props> = () => {
  const offersItem: Ref<HTMLLIElement> = useRef(null)
  const clouds: Ref<HTMLDivElement> = useRef(null)
  const [windowWidth, setWindowWidth] = useState(0)
  const [activeItem, setActiveItem] = useState<{
    left?: number | string
    bottom?: number | string
  }>({
    left: 0,
    bottom: -20,
  })

  const updateCloudsPos = (target?: HTMLElement) => {
    const element = target ? target : offersItem.current
    const cloudsElement = clouds && clouds.current ? clouds.current : null

    if (element && cloudsElement) {
      const elementWidth = element.offsetWidth ?? 0
      const cloudsWidth = cloudsElement.offsetWidth ?? 150

      setActiveItem({
        left: elementWidth / 2 - cloudsWidth / 2 + element.offsetLeft,
        bottom: -20,
      })
    }
  }

  const handleHover = (e: MouseEvent<HTMLLIElement>) => {
    window.innerWidth >= 1024 && updateCloudsPos(e.currentTarget)
  }

  useEffect(() => {
    const loadEvent = () => {
      // setWindowWidth(window.innerWidth)
      window.innerWidth >= 1024 && updateCloudsPos()
    }

    window.addEventListener('load', loadEvent)
    return () => window.removeEventListener('load', loadEvent)
  }, [])

  return (
    <HomeOffersContainer>
      <SecondaryTitle as="h3">
        <Trans id="home.offers.title">Pourquoi travailler avec nous ?</Trans>
      </SecondaryTitle>
      <HomeOffersList>
        {homeOffersData.map((item, i) => (
          <HomeOffersItem
            key={i}
            onMouseEnter={handleHover}
            ref={i === 0 ? offersItem : null}
          >
            <MainText>
              <Trans id={item.langID} />
            </MainText>
          </HomeOffersItem>
        ))}
        <HomeOffersClouds
          animate={{
            bottom: activeItem.bottom,
            left: activeItem.left,
          }}
          transition={{ duration: 0.4, ease: 'easeInOut' }}
          ref={clouds}
        >
          <img src={HomeCloud} alt="Cloud image" />
          <img src={HomeCloud} alt="Cloud image" />
        </HomeOffersClouds>
      </HomeOffersList>
    </HomeOffersContainer>
  )
}

export default HomeOffers
