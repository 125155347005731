import React, { FunctionComponent } from 'react'
import Image from 'components/Image'
import {
  ImageContainer,
  ImageExtra1,
  ImageExtra2,
  ImageExtra3,
} from './HomeTeamsImage.style'
import { ImageQueryModel } from 'models/imageQuery.model'

interface Props {
  sources: ImageQueryModel
}

const HomeTeamsImage: FunctionComponent<Props> = ({ sources }) => {
  return (
    <ImageContainer>
      <div className="rellax" data-rellax-speed="2">
        <Image
          fluid={sources.teamsAvatar.fluid}
          alt={sources.teamsAvatar.alt}
        />
      </div>
      <ImageExtra1 className="rellax" data-rellax-speed="2">
        <Image fluid={sources.extra1.fluid} alt={sources.extra1.alt} />
      </ImageExtra1>
      <ImageExtra2 className="rellax" data-rellax-speed="3">
        <Image fluid={sources.extra2.fluid} alt={sources.extra1.alt} />
      </ImageExtra2>
      <ImageExtra3 className="rellax" data-rellax-speed="4">
        <Image fluid={sources.extra3.fluid} alt={sources.extra1.alt} />
      </ImageExtra3>
    </ImageContainer>
  )
}

export default HomeTeamsImage
